import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {useGetLandCoverageNotPaginatedQuery} from "../../../redux/api/asset";
import {skipToken} from "@reduxjs/toolkit/query";
import {Table, Tag} from "antd";
import CoordinatesAssetMissing from "../../common/CoordinatesAssetMissing";
import {ColumnsType} from "antd/lib/table";
import {useGetUserProfileQuery} from "../../../redux/api/locales";
import CustomNumericFormat from "../../common/customNumeric/CustomNumericFormat";
import {capitalize} from "lodash";
import {LANDCOVERAGE_SOURCE} from "../../../types/api/assets";
import {LAND_COVERAGE_ALL_SOURCE} from "../SelectLandCoverageSource";

type DataSource = {
  key: string,
  data_source: LANDCOVERAGE_SOURCE,
  name: string,
  area_Km2: number,
  coverage: number,
  area_Deg2: number,

}
export default function LandCoverageTable({currentSource}: { currentSource?: LANDCOVERAGE_SOURCE | (typeof LAND_COVERAGE_ALL_SOURCE) }) {
  const [_, {asset}] = useAssetDetails()
  const {t} = useTranslation()

  const isMissingCoordinates = asset?.position === null
  const {data: userProfile} = useGetUserProfileQuery()
  const {
    data,
    isFetching,
    isUninitialized
  } = useGetLandCoverageNotPaginatedQuery(asset?.uuid && !isMissingCoordinates ? {
    assetUuid: asset.uuid,
  } : skipToken)

  const columns: ColumnsType<DataSource> = [
    {
      key: "name",
      dataIndex: "name",
      title: capitalize(t("assets.landCoverageName"))
    },
    {
      key: "data_source",
      dataIndex: "data_source",
      title: capitalize(t("assets.landCoverageDataSource")),
      render: (v, record) => {
        const color = record.data_source === LANDCOVERAGE_SOURCE.WDPA ? 'green' : record.data_source === LANDCOVERAGE_SOURCE.CORINE ? 'blue' : undefined
        return <Tag color={color}>{v}</Tag>
      }
    },
    {
      key: "coverage",
      dataIndex: "coverage",
      title: capitalize(t("assets.landCoverage")),
      render: v => <CustomNumericFormat
        value={v * 100}
        displayType={'text'}
        suffix={" %"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    },
    {
      key: "area_Km2",
      dataIndex: "area_Km2",
      title: capitalize(t("assets.landCoverageAreaKm2")),
      render: v => <CustomNumericFormat
        value={v}
        displayType={'text'}
        suffix={" km²"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    },
    {
      key: "area_Deg2",
      dataIndex: "area_Deg2",
      title: capitalize(t("assets.landCoverageAreaDeg2")),
      render: v => <CustomNumericFormat
        value={v}
        displayType={'text'}
        suffix={" deg²"}
        decimalScale={8}
        fixedDecimalScale={true}
      />
    },
  ]
  const dataSource = useMemo(() =>  data?.filter(el => !currentSource || currentSource === LAND_COVERAGE_ALL_SOURCE || el.data_source === currentSource ).flatMap(source => source.data?.map((currentEl, idx) => {
    const name = userProfile?.language === 'it' ? currentEl.label_it : currentEl.label_en
    return {
      key: `${source.data_source}-${name}-${idx}`,
      data_source: source.data_source,
      name,
      area_Km2: currentEl.area_Km2,
      coverage: currentEl.coverage,
      area_Deg2: currentEl.area_Deg2,
    }
  })) ?? [], [currentSource, data, userProfile?.language])

  return <>
    {isMissingCoordinates ?
      <CoordinatesAssetMissing/>
      :
      <>
        <Table
          <DataSource>
          loading={(isFetching || isUninitialized) && !isMissingCoordinates}
          columns={columns}
          scroll={{x: true}}
          dataSource={dataSource}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => t('table.pageSizeOfTotal', {
              rangeStart: range[0],
              rangeEnd: range[1],
              total,
            }),
            position: ["bottomCenter"]
          }}
        />
      </>}
  </>
}