import {Card, Tabs, TabsProps} from "antd";
import {useTranslation} from "react-i18next";
import React, {useMemo, useState} from "react";
import LandCoverageTable from "./LandCoverageTable";
import {capitalize} from "lodash";
import BiodiversityTable from "./BiodiversityTable";
import {BIODIVERSITY_SOURCE, LANDCOVERAGE_SOURCE} from "../../../types/api/assets";
import {ALL_BIODIVERSITY_SOURCE} from "../../assets/AssetsTable/filters/BiodiversitySourceFilter";
import SelectBioDiversitySource from "../SelectBioDiversitySource";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import SelectLandCoverageSource, {LAND_COVERAGE_ALL_SOURCE} from "../SelectLandCoverageSource";


type TabsKeys = 'biodiversity' | 'landCoverage'
export default function EnvironmentCard() {

  const {t} = useTranslation()
  const {xs} = useBreakpoint()
  const [activeKey, setActiveKey] = useState<TabsKeys>('biodiversity')
  const [biodiversitySource, setBiodiversitySource] = useState<BIODIVERSITY_SOURCE | (typeof ALL_BIODIVERSITY_SOURCE)>(ALL_BIODIVERSITY_SOURCE)
  const [landCoverageSource, setLandCoverageSource] = useState<typeof LAND_COVERAGE_ALL_SOURCE | LANDCOVERAGE_SOURCE>(LAND_COVERAGE_ALL_SOURCE)
  const extra = useMemo(() => activeKey === 'biodiversity' ? <SelectBioDiversitySource
    selectProps={{
      value: biodiversitySource,
      onSelect: (v) => setBiodiversitySource(v)
    }}
    text={{
      ...xs && {children: null}
    }}
  /> : <SelectLandCoverageSource
    selectProps={{
      value: landCoverageSource,
      onSelect: (v) => setLandCoverageSource(v)
    }}
    text={{
      ...xs && {children: null}
    }}
  />, [activeKey, biodiversitySource, landCoverageSource, xs])


  const items: TabsProps["items"] = [
    {
      key: 'biodiversity',
      label: capitalize(t('assets.biodiversity')),
      children: <Card>
        <BiodiversityTable currentSource={biodiversitySource}/>
      </Card>,
    },
    {
      key: 'landCoverage',
      label: capitalize(t('assets.landCoverage')),
      children: <Card>
        <LandCoverageTable currentSource={landCoverageSource}/>
      </Card>
    }
  ]

  return <Tabs
    items={items}
    activeKey={activeKey}
    onChange={activeKey => setActiveKey(activeKey as TabsKeys)}
    tabBarExtraContent={extra}
  />
}